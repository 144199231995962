<template>
  <div class="relative mx-auto text-center max-w-4xl" :class="conf.containerPadding">
    <h4 class="font-serif" :class="conf.headingClass" v-if="showHeading">
      <slot name="heading">Something went wrong.</slot>
    </h4>
    <p :class="conf.descClass">
      <slot>We had an unexpected issue loading this content.</slot>
    </p>
    <p v-if="action">
      <u-button color="charcoal"  @click="action" :size="size">{{actionText}}</u-button>
    </p>
  </div>
</template>

<script setup>
  const props = defineProps({
    action: {
      type: Function
    },
    actionText: {
      type: String,
      default: 'Try Again'
    },
    showHeading: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    }
  });

  const conf = computed(() => {
    const conf = {};

    switch (props.size) {
      case 'xs':
        conf.descClass = 'text-xs mb-2';
        conf.headingClass = 'text-base mb-1';
        conf.containerPadding = 'p-2';
        break;
      case 'sm':
        conf.descClass = 'text-sm mb-2';
        conf.headingClass = 'text-xl mb-1';
        conf.containerPadding = 'p-6 pr-10';
        break;
      case 'md':
        conf.descClass = 'text-xl mb-4';
        conf.headingClass = 'text-2xl sm:text-3xl mb-2';
        conf.containerPadding = 'p-6 pr-10';
        break;
    }
    return conf;
  });

</script>
