<template>
  <div class="text-center" :class="conf.padding">
    <core-busy-dots :width="conf.dotSize" />
    <slot>
      <p class="font-serif opacity-90 text-charcoal" :class="[conf.textBase, conf.textClass]">{{loadingText}}</p>
    </slot>
  </div>
</template>

<script setup>
  const props = defineProps({
    loadingText: {
      type: String,
      default: 'Loading Good Things'
    },
    size: {
      type: String,
      default: 'md'
    }
  });

  const conf = computed(() => {
    const conf = {
      textBase: 'text-charcoal dark:text-white/80'
    };

    switch (props.size) {
      case 'xs':
        conf.textClass = 'text-base pt-1';
        conf.dotSize = 40;
        conf.padding = 'py-2';
        break;
      case 'sm':
        conf.textClass = 'text-xl pt-1';
        conf.dotSize = 50;
        conf.padding = 'py-4';
        break;
      case 'md':
        conf.textClass = 'text-2xl sm:text-3xl pt-2';
        conf.dotSize = 65;
        conf.padding = 'py-6';
        break;
    }

    return conf;
  })
</script>

